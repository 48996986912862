<template>
  <main id="content" role="main" class="bg-light">
    <!-- Privacy Section -->
    <div class="container space-top-3 space-top-lg-4 space-bottom-2 space-bottom-lg-3 overflow-hidden">
      <div class="w-lg-80 mx-lg-auto">
        <div class="card">
          <!-- Hero -->
          <div class="card-header position-relative bg-primary space-1 space-md-2 p-4 px-md-7 px-md-9">
            <h1 class="text-white">beNovelty Privacy Policy for FabriXAPI</h1>
            <p class="text-white-70">Last modified: August, 2023 <!-- (<a class="text-light" href="#">view archived versions</a>) --></p>

            <!-- SVG Bottom Shape -->
            <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1" width="100%" height="32">
                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
              </svg>
            </figure>
            <!-- End SVG Bottom Shape Section -->
          </div>
          <!-- End Hero -->

          <!-- Content -->

          <div class="card-body p-4 p-md-9">

            <!-- ===== Intro ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h2 class="h3">Introduction</h2>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>beNovelty is committed to respecting the privacy rights of visitors to its website. This privacy policy explains how we collect, store and use personal data about you when you browse the Website or otherwise provide your personal data to us whether through the Website or the submission of forms or documents (in whatever formats) when you participate in our activities, program, etc or otherwise. Together with the terms and conditions that may be specified in such forms or documents (in whatever format) through which you submit your personal data, this privacy policy provides you with details about the types of personal data that we collect from you, how we use your personal data and the rights you have to control our use of your personal data.</p>
              <p>You must read this privacy policy, and where applicable, the terms and conditions specified in the forms and documents (in whatever format) through which you submit personal data to us carefully and we recommend that you print and/or retain a copy for your future reference. By accessing, browsing or otherwise using this Website, or by submitting your personal data to us through completing and submitting forms or documents (in whatever format), you confirm that you have read, understood and agree to this privacy policy, and where applicable, the relevant terms and conditions specified in those forms or documents in its entirety. If you do not agree to this privacy policy, or where applicable, the relevant terms and conditions specified in the forms or documents (in whatever format) through which you submit your personal data to us in its entirety, you must not use this Website and/or submit your personal data to us.</p>
              <p>We respect your right to privacy and will only process personal data you provide to us in compliance with the requirements of the Personal Data (Privacy) Ordinance in Hong Kong or the Personal Data Protection law (Law No. 8/2005) in Macau, as applicable in your jurisdiction.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Intro ===== -->

            <!-- ===== Scope ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Scope</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>This Privacy Policy applies only to your use of Our Site. It does not extend to any websites that are linked to from Our Site (whether We provide those links or whether they are shared by other users). We have no control over how your data is collected, stored or used by other websites and We advise you to check the privacy policies of any such websites before providing any data to them.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Scope ===== -->

            <!-- ===== Minor ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Minor</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>We do not and will not knowingly collect information from any unsupervised person under the age of 18. If you are under the age of 18, you must not use this Website or submit any personal data to us through whatever means unless you have the consent of, and are supervised by, a parent or guardian.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Minor ===== -->

            <!-- ===== what data ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>What Data do We Collect?</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>Depending upon your use of Our Site, We may collect some or all of the following data:</p>
              <ul>
                <li>Your Name</li>
                <li>Your E-mail Address</li>
                <li>Any other data, information, work in any format you submit to us through our website, e-mail or any other channel. Examples include but not limited to business name, job title, profession, telephone number etc which are sent to us as Request Description in Online Inquiry or Job Application with e-mail.</li>
              </ul>
              <!-- End Text -->
            </div>
            <!-- ===== End What data ===== -->

            <!-- ===== How data ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>How do We Collect Your Personal Data?</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>Some data will be collected automatically by Our Site (for further details, please see on the section of Our use of Cookies), other data will only be collected if you voluntarily submit it and consent to Us using it for when you submit your enquiry or job application, or sign up to receive beNovelty newsletters or subscription through the Website.</p>
              <p>In addition, we may collect anonymised details about visitors to our Website for the purposes of aggregate statistics or reporting purposes. However, no single individual will be identifiable from the anonymised details that we collect for these purposes.</p>
              <p>We may also collect the personal data, including your name and email address as stipulated in the respective forms and/or other documents (in whatever format) when you submit the same to us.</p>
              <p>In some cases, the collection of data may be a statutory or contractual requirement, and We will be limited in the products and/or services. We can provide you without your consent for Us to be able to use such data.</p>
              <p>Personal data may also be collected from third parties who have your consent to pass your details to us. You understand and confirm that your provision of personal data is done voluntarily.</p>
              <p>By submitting the personal data whether through the Website or other means, you warrant and confirm that you have the proper authorization to make the submissions, and all the information submitted is real and correct.</p>
              <p>If you are under 18 years of age, you shall consult and obtain consent from your parents / legal guardians to submit your personal data before so doing. By submission of your personal data, you have signified that you have obtained such consent from your parents / legal guardians.</p>
              <p>We will ensure that your personal data is processed lawfully, fairly, and transparently, without adversely affecting your rights. We will only process your personal data if at least one of the following basis applies:</p>
              <ol>
                <li>you have given consent to the processing of your personal data for one or more specific purposes;</li>
                <li>processing is necessary for the performance of a contract to which you are a party or in order to take steps at the request of you prior to entering into a contract;</li>
                <li>processing is necessary for compliance with a legal obligation to which we are subject;</li>
                <li>processing is necessary to protect the vital interests of you or of another natural person;</li>
                <li>processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller; and/or</li>
                <li>processing is necessary for the purposes of the legitimate interests pursued by us or by a third party, except where such interests are overridden by the fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child</li>
              </ol>
              <!-- End Text -->
            </div>
            <!-- ===== End How data ===== -->

            <!-- ===== Purpose data ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Purpose of collection</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>We (and third party data processors acting on our behalf) may collect, store and process your personal data:</p>
              <ul>
                <li>to reply to the enquiries raised by you;</li>
                <li>to make this Website available to you and to provide you with content which is tailored to your individual tastes or needs;</li>
                <li>with your consent, to contact you about beNovelty offers and sending you newsletters, marketing promotion materials in relation to beNovelty services and/or applications, and information regarding our activities, announcement, campaigns, events and survey relating to our services and applications;</li>
                <li>to provide our services, applications and / or beta testing of products / services to you;</li>
                <li>to contact you, to solicit further information and/or to provide you with the services as stipulated in and in accordance with the forms and/or other documents (in whatever format) through which you submit your personal data to us;</li>
                <li>to process your job application;</li>
                <li>for the purposes specified in the forms and/or other documents (in whatever format) through which you submit your personal data to us;</li>
                <li>for research, analysis, testing, monitoring, risk management and administrative purposes; and</li>
                <li>for any purposes directly related to the above, or where we have a legal right or duty (without any requirement of consent) to use or disclose your information (including for crime and fraud prevention and related purposes).</li>
              </ul>
              <p>Unless otherwise indicated, your submission of the personal data shall be deemed as your authorization to us, our subsidiaries, associated companies, service providers and / or agents for collecting, processing and using your personal data for the above mentioned purposes.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Purpose data ===== -->

            <!-- ===== Use of Personal Data ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Use of personal data</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>Unless you indicated otherwise, the personal data provided will be used by beNovelty only.</p>
              <p>Notwithstanding the aforesaid, we will not share your personal data to others for their use of the personal data in direct marketing (save for sharing your personal data to our service providers / agents for direct marketing on our behalf) unless a written consent is obtained from you.</p>
              <p>beNovelty (by beNovelty Limited) will endeavour to ensure that your personal data are being protected from any unauthorized or accidental access, process or clearance. beNovelty Limited will comply with the requirements of the Personal Data (Privacy) Ordinance (Cap. 486 of the laws of Hong Kong) or the Personal Data Protection law (Law No. 8/2005) in Macau in processing your personal data.</p>
              <p>If you do not wish to receive further promotional offers, newsletter or news from us, please write to us at info@benovelty.com.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Use of Personal Data ===== -->

            <!-- ===== Storage of Personal Data ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Storage of personal data</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>We only keep your data for as long as We need to in order to use it as described above in Purpose of collection, and/or for as long as We have your permission to keep it. In any event, We will conduct an annual review to ascertain whether we need to keep your data. Your data will be deleted if we no longer need it.</p>
              <p>Data security is of great importance to Us, and to protect your data We have put in place suitable physical, electronic and managerial procedures to safeguard and secure data collected through Our Site.</p>
              <p>Steps We take to secure and protect your data include:</p>
              <ul>
                <li>Roles and Permissions to access data</li>
                <li>Masking Sensitive Data - Encryption and Pseudonymization</li>
                <li>Audit Trail of Database Activity</li>
                <li>Alerts to notify unauthorized breach attempt</li>
                <li>Alerts and preventive measures on accidental loss of data</li>
                <li>Scanning for data duplications</li>
              </ul>
              <p>Notwithstanding the security measures that We take, it is important to remember that the transmission of data via the internet may not be completely secure and that you are advised to take suitable precautions when transmitting to Us data via the internet.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Storage of Personal Data ===== -->

            <!-- ===== Change Hands ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>What Happens if Our Business Changes Hands?</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>We may, from time to time, expand or reduce Our business and this may involve the sale and/or the transfer of control of all or part of Our business. Data provided by users will, where it is relevant to any part of Our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the data for the purposes for which it was originally collected by Us.</p>
              <p>In the event that any of your data is to be transferred in such a manner, you will be contacted in advance and informed of the changes.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Change Hands ===== -->

            <!-- ===== Third Party Sites ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Third party sites</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>This Website contains links to other social media websites and websites operated by third parties (e.g. Twitter, Facebook). Please note that this privacy policy applies only to the personal data that we collect through this Website or other means and we cannot and will not be responsible for personal data that third parties may collect, store and use through their website (including microsites). You should always read the privacy policy of each website that you visit carefully.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Third Party Sites ===== -->

            <!-- ===== Our use of Cookies ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Our use of Cookies</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>Our Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and/or services.  By using Our Site you may also receive certain third party Cookies on your computer or device. Third party Cookies are those placed by websites, services, and/or parties other than Us. We use third party Cookies such as Mailchimp to provide enquiry services.  In addition, Our Site uses analytics services provided by Google Analytics, which also use Cookies. Website analytics refers to a set of tools used to collect and analyse usage statistics, enabling Us to better understand how people use Our Site.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Our use of Cookies ===== -->

            <!-- ===== Withdraw ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Your right to withdraw or withhold Information</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>You may access Our Site without providing any data at all. However, to use all features and functions available on Our Site or product and/or service We provide you may be required to submit or allow for the collection of certain data.</p>
              <p>You may restrict your internet browser's use of Cookies. For more information, see Our use of Cookies.</p>
              <p>You may withdraw your consent for Us to use your personal data as set out in What Data do We Collect? at any time by contacting Us using the details set out in Contacting Us, and We will delete Your data from Our systems.  However, you acknowledge this may limit Our ability to provide the best possible products and/or services to you.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Withdraw ===== -->

            <!-- ===== Access to Information ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Access to Information</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>You have the right to request access to and correct your personal data retained by the us. You may make written request to us at info@benovelty.com We reserve the rights to charge you reasonable administrative costs.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Access to Information ===== -->

            <!-- ===== Summary of Your Rights ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Summary of Your Rights</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>Under the law, you have:</p>
              <ul>
                <li>the right to request access to, deletion of or correction of, your personal data held by Us;</li>
                <li>the right to complain to a supervisory authority;</li>
                <li>be informed of what data processing is taking place;</li>
                <li>the right to restrict processing;</li>
                <li>the right to data portability;</li>
                <li>object to processing of your personal data;</li>
                <li>rights with respect to automated decision-making and profiling</li>
              </ul>
              <p>To enforce any of the foregoing rights or if you have any other questions about Our Site or this Privacy Policy, please contact Us using the details set out in Contacting Us.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Summary of Your Rights ===== -->

            <!-- ===== Contacting Us ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Contacting Us</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>If you have any questions about Our Site or this Privacy Policy, please contact Us by email at info@benovelty.com, by telephone on +852 3598 3658, or by post at beNovelty Limited, Unit 320, 3/F, Building 16W, Phase 3, No. 16 Science Park West Avenue, Hong Kong Science Park, Shatin, N.T., Hong Kong. For any issue you find appropriate, you may also contact our Data Protection Officer (DPO) by email at dataprotection@beNovelty.com or by post attending to beNovelty Limited, Unit 320, 3/F, Building 16W, Phase 3, No. 16 Science Park West Avenue, Hong Kong Science Park, Shatin, N.T., Hong Kong.</p>
              <p>Please ensure that your query is clear, particularly if it is a request for information about the data We hold about you (as under Access to Information, above).</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Contacting Us ===== -->

            <!-- ===== Updates to this policy ===== -->
            <div class="mb-7">
              <!-- Title -->
              <div class="mb-3">
                <h3>Updates to this policy</h3>
              </div>
              <!-- End Title -->
              <!-- Text -->
              <p>This privacy policy was last updated in August 2023. Please check back regularly to keep informed of updates to this privacy policy. We reserve the sole and absolute discretion to amend and update this privacy policy without prior notice.</p>
              <!-- End Text -->
            </div>
            <!-- ===== End Updates to this policy ===== -->
          </div>
          <!-- End Content -->

        </div>
      </div>
    </div>
    <!-- End Privacy Section -->
  </main>
</template>

<script>
export default {
  name: 'Privacy',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Privacy Policy for FabriXAPI| %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/privacy-policy' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Privacy Policy for FabriXAPI| beNovelty' },
        { property: 'og:description', content: 'We care about your privacy. beNovelty is committed to respecting the privacy rights of visitors to our website.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/privacy-policy' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
ol {
  list-style: block;
  padding-inline-start: 40px;
}
</style>
